<template>
    <div class="hotTopic-container">
        <div class="search-topic">
            <el-input placeholder="输入关键词" v-model="searchInput" prefix-icon="el-icon-search" style="width: 270px" @keyup.enter.native="searchBtn"></el-input>
        </div>
        <el-table :data="hotTopicList" :style="hotTopicList.length > 0 ? 'min-height: calc(100vh - 170px)' : 'min-height: calc(100vh - 120px)'"
                  class="listTable" header-cell-class-name="table-header-cell"
                  :header-cell-style="{fontWeight: 'normal', color: '#16113F', background: '#F1F0FE'}" :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column label="热度">
                <template slot-scope="scope">
                    <div style="padding-left: 60px" class="hot-bar">
                        <i class="iconfont">&#xe650;</i>
                        <span>{{scope.row.active_index}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="c_name" label="话题"></el-table-column>
            <el-table-column prop="view_count" label="播放总量" align="center"></el-table-column>
            <el-table-column prop="user_count" label="抖音使用人数" align="center"></el-table-column>
            <el-table-column label="相关视频" width="450" align="center">
                <template slot-scope="scope">
                    <div class="hotTopic-carousel-box">
                        <swiper :ref="`topicCarousel_${scope.row.id}`" :auto-update="true" class="swiper hotTopic-carousel" :options="getSwiperOption(scope.row.id)">
                            <swiper-slide class="item" v-for="videoItem in scope.row.videos_data">
                                <a :href="videoItem.share_url" target="_blank">
                                    <div class="cover">
                                        <img :src="videoItem.video_cover_url" alt="">
                                    </div>
                                </a>
                            </swiper-slide>
                        </swiper>
                        <div class="topic-swiper-button-prev" :class="`swiper-button-prev_${scope.row.id}`" slot="button-prev" @click="prevBtn(scope.row.id)">
                            <i class="iconfont">&#xe8ef;</i>
                        </div>
                        <div class="topic-swiper-button-next" :class="`swiper-button-next_${scope.row.id}`" slot="button-next" @click="nextBtn(scope.row.id)">
                            <i class="iconfont">&#xe8f1;</i>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" v-if="hotTopicList.length > 0" style="padding-bottom: 10px"
                       :current-page.sync="hotTopicPages.currentPageNum"
                       :page-size="hotTopicPages.eachPageNum"
                       :total="hotTopicPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="topicCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        data() {
            return {
                hotTopicList: [],
                hotTopicPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                searchInput: '',
            }
        },
        mounted() {
            this.getHotTopicList();
        },
        methods: {
            getSwiperOption(id) {
                return {
                    // observer: true,
                    // observeParents: true,
                    slidesPerView: 3,
                    spaceBetween: 30,
                    slidesPerGroup: 3,
                    initialSlide: -1,
                    loopFillGroupWithBlank: true,
                    navigation: {
                        nextEl: `.swiper-button-next_${id}`,
                        prevEl: `.swiper-button-prev_${id}`,
                        disabledClass: 'topic-swiper-button-disabled',
                    },
                }
            },
            searchBtn() {
                this.hotTopicPages.currentPageNum = 1
                this.getHotTopicList()
            },
            getHotTopicList() {
                let params = {
                    paging: 1,
                    c_name: this.searchInput,
                    page: this.hotTopicPages.currentPageNum,
                    pageSize: this.hotTopicPages.eachPageNum
                }
                this.$httpStudent.axiosGetBy(this.$api.hotTopic, params, (res) => {
                    if (res.code === 200) {
                        this.hotTopicList = res.data.data
                        this.hotTopicPages.total = res.data.total
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            topicCurrentChange(val) {
                this.hotTopicPages.currentPageNum = val;
                this.getHotTopicList();
            },
            prevBtn(id) {
                this.$refs[`topicCarousel_${id}`].$swiper.slidePrev();
            },
            nextBtn(id) {
                this.$refs[`topicCarousel_${id}`].$swiper.slideNext();
            }
        }
    }
</script>

<style scoped lang="scss">
    .hotTopic-container {
        background: #fff;
        padding-top: 20px;
    }
    ::v-deep .el-table__expanded-cell {
        padding: 0;
        background: #F0F0F0;
        position: relative;
        height: 222px;
        width: 100%;
        &:hover {
            background-color: #F0F0F0!important;
        }
    }
    .hotTopic-carousel-box {
        width: 430px;
        position: relative;
        .hotTopic-carousel {
            width: 300px;
            .item {
                & > a {
                    color: #999;
                    display: block;
                    .cover {
                        width: 80px;
                        height: 96px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        background: #F1F0FE;
                        border-radius: 6px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .text-overflow-2 {
                        margin: 8px 0 0;
                    }
                }
            }
        }
        .topic-swiper-button-prev, .topic-swiper-button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;
            z-index: 9;
            .iconfont {
                font-size: 16px;
                display: block;
                color: #444;
                transition: all .3s;
            }
            &:hover {
                .iconfont {
                    color: #685EF3;
                }
            }
        }
        .topic-swiper-button-prev {
            left: 0;
        }
        .topic-swiper-button-next {
            right: 0;
        }
        .topic-swiper-button-disabled {
            opacity: 0;
            cursor: auto;
        }
    }
    .listTable {
        width: 100%;
    }
    .hot-bar {
        color: #F73657;
        .iconfont {
            font-size: 12px;
            margin-right: 8px;
        }
    }
    ::v-deep .table-header-cell {
        &:first-child {
            padding-left: 60px;
        }
    }
    .search-topic {
        padding: 0 20px 12px 0;
        text-align: right;
        .el-input {
            ::v-deep .el-input__inner {
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                border-color: #594FEE;
            }
            ::v-deep .el-input__icon {
                line-height: 30px;
            }
        }
    }
</style>